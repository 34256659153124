body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card1{
  border :1px solid ;
  border-radius: 10px;
  box-shadow: 5px 5px 5px;
  padding : 20px;
  margin : 10 px;
  text-align: center;
  max-width: 1000px;
  margin-left: 0%;
  margin-top: 0%;
  margin-right: 0%;
  margin-bottom: 0%;
  display: inline-flex;
  font-family:Arial, Helvetica, sans-serif;
  font-size: small;

};
